import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/max/Documents/node-projects/maxs-portfolio/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://maxhogan.dev/"
      }}>{`MaxHogan.dev`}</a>{` is made with Gatsby and React, and hosted on Firebase. MDX is used for all the content (see the `}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/website/tree/main/src/pages"
      }}>{`GitHub`}</a>{`). You can view the source on `}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/website"
      }}>{`GitHub`}</a>{`. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      